<script setup lang="ts">
import adminAvatar from '@images/avatars/i-field.svg?url'
import { fileType } from '@core/utils/helpers'

const props = defineProps<Props>()

const emit = defineEmits<Emit>()

const apiUrl = useRuntimeConfig().public.apiBaseUrl

const userData = useCookie<any>('userData')
const clientStoreChatStore = useClientStoreChatStore()
const notificationStore = useNotificationStore()

interface Emit {
  (e: 'update:isChatVisible', value: boolean): void
  (e: 'updatedData', val: any): void
}

interface Props {
  selected: object
  url: string
  isChatVisible: boolean
  isAdmin?: boolean
  isOwner?: boolean
}

const isFormValid = ref(false)
const file = ref()
const content = ref()

const dialogModelValueUpdate = (val: boolean) => {
  emit('update:isChatVisible', val)

  file.value = null
  content.value = ''
  isFormValid.value = false
}

// Perfect scrollbar
const chatLogPS = ref(null)

const scrollToBottomInChatLog = () => {
  if (props.isChatVisible && chatLogPS.value) {
    const scrollEl = chatLogPS.value.$el || chatLogPS.value

    scrollEl.scrollTop = scrollEl.scrollHeight
  }
}

const sendMessage = () => {
  const formData = new FormData()

  formData.append('owner_chat_id', props.selected.id)
  formData.append('entry_chat_id', props.selected.id)
  formData.append('client_store_chat_id', props.selected.id)
  formData.append('content', isNullOrUndefined(content.value) ? '' : content.value)

  if (file.value)
    formData.append('file', file.value[0])

  clientStoreChatStore.store(formData, props.url).then(response => {
    emit('updatedData', response)

    // update notification
    if (props.url === '/owner_chat_messages')
      notificationStore.fetchesOwner()
    else
      notificationStore.fetches(userData.value.role === 'admin')

    isFormValid.value = false
    file.value = null
    content.value = ''
  })
}

const title = computed(() => props.url === '/owner_chat_messages' ? props.selected.owner.name : props.selected.client_store.name)

watch(() => ({ file, content }), () => {
  if ((!file.value || file?.value.length === 0) && !content.value)
    isFormValid.value = false
  else
    isFormValid.value = true
}, { deep: true })

onMounted(() => {
  scrollToBottomInChatLog()
})

onUpdated(() => {
  scrollToBottomInChatLog()
})
</script>

<template>
  <VDialog
    :title="title"
    :width="$vuetify.display.smAndDown ? 'auto' : 680"
    scrollable
    content-class="scrollable-dialog"
    :model-value="props.isChatVisible"
    @update:model-value="dialogModelValueUpdate"
  >
    <DialogCloseBtn @click="dialogModelValueUpdate(false)" />

    <VCard height="100vh">
      <VCardTitle>
        {{ title }}
      </VCardTitle>
      <VCardText ref="chatLogPS">
        <div
          v-for="message in props.selected.messages"
          :key="message.id"
          class="chat-log pa-5"
        >
          <div v-if="userData.role === 'client' || userData.role === 'owner'">
            <div v-if="message.is_admin">
              <div class="chat-group align-start">
                <div class="chat-avatar me-4">
                  <VAvatar variant="tonal">
                    <VImg :src="adminAvatar" />
                  </VAvatar>
                  システム管理者
                </div>
              </div>
              <div class="chat-group align-start">
                <div class="chat-body d-inline-flex flex-column align-start">
                  <div class="text-right">
                    <span class="text-sm ms-1 text-disabled">{{ $dayjs(message.created_at).format('YYYY年MM月DD日 (dd)') }}</span>
                  </div>
                  <p
                    v-if="message.content"
                    class="content-overflow-wrap chat-content py-2 px-4 elevation-1 chat-left mb-3"
                    style="background-color: rgb(var(--v-theme-surface));"
                    v-html="message.content"
                  />
                  <a
                    v-if="message.file_path"
                    :href="`${apiUrl}/storage/${message.file_path}`"
                    target="_blank"
                  >
                    <div v-if="fileType(message.file_path) === 'png'">
                      <VImg
                        :width="400"
                        cover
                        :src="`${apiUrl}/storage/${message.file_path}`"
                        class="rounded"
                      />
                      <div>
                        <label>{{ file?.file_name }}</label>
                      </div>
                    </div>
                    <span v-else>{{
                      message.file_path.slice(
                        message.file_path.lastIndexOf("/") + 1,
                      )
                    }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="chat-group d-flex align-start flex-row-reverse mb-4">
                <div class="chat-avatar">
                  {{ title }}
                  <VAvatar variant="tonal">
                    <VImg
                      v-if="userData && userData.avatar"
                      :src="`${apiUrl}/storage/${userData.avatar}`"
                      cover
                    />
                    <VIcon
                      v-else
                      icon="tabler-user"
                    />
                  </VAvatar>
                </div>
              </div>
              <div class="chat-group d-flex align-start flex-row-reverse mb-4">
                <div class="chat-body d-inline-flex flex-column align-end">
                  <div>
                    <span class="text-sm ms-1 text-disabled">{{ $dayjs(message.created_at).format('YYYY年MM月DD日 (dd)') }}</span>
                  </div>
                  <p
                    v-if="message.content"
                    class="content-overflow-wrap chat-content py-2 px-4 elevation-1 bg-secondary text-white chat-right mb-3"
                    v-html="message.content"
                  />
                  <a
                    v-if="message.file_path"
                    :href="`${apiUrl}/storage/${message.file_path}`"
                    target="_blank"
                  >
                    <div v-if="fileType(message.file_path) === 'png'">
                      <VImg
                        :width="400"
                        cover
                        :src="`${apiUrl}/storage/${message.file_path}`"
                        class="rounded"
                      />
                    </div>
                    <span v-else>{{
                      message.file_path.slice(
                        message.file_path.lastIndexOf("/") + 1,
                      )
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="message.is_admin">
              <div class="chat-group d-flex align-start flex-row-reverse mb-4">
                <div class="chat-avatar">
                  <VAvatar variant="tonal">
                    <VImg :src="adminAvatar" />
                  </VAvatar>
                  システム管理者
                </div>
              </div>
              <div class="chat-group d-flex align-start flex-row-reverse mb-4">
                <div class="chat-body d-inline-flex flex-column align-end">
                  <div>
                    <span class="text-sm ms-1 text-disabled">{{ $dayjs(message.created_at).format('YYYY年MM月DD日 (dd)') }}</span>
                  </div>
                  <p
                    v-if="message.content"
                    class="content-overflow-wrap chat-content py-2 px-4 elevation-1 bg-secondary text-white chat-right mb-3"
                    v-html="message.content"
                  />
                  <a
                    v-if="message.file_path"
                    :href="`${apiUrl}/storage/${message.file_path}`"
                    target="_blank"
                  >
                    <div v-if="fileType(message.file_path) === 'png'">
                      <VImg
                        :width="400"
                        cover
                        :src="`${apiUrl}/storage/${message.file_path}`"
                        class="rounded"
                      />
                    </div>
                    <span v-else>{{
                      message.file_path.slice(
                        message.file_path.lastIndexOf("/") + 1,
                      )
                    }}</span>
                  </a>
                  <div v-if="isOwner">
                    <span
                      v-if="message.owner_checked_at"
                      class="text-sm ms-1 text-disabled"
                    >{{ $dayjs(message.owner_checked_at).format('YYYY年MM月DD日 HH:mm') }} 既読</span>
                  </div>
                  <div v-else>
                    <span
                      v-if="message.client_checked_at"
                      class="text-sm ms-1 text-disabled"
                    >{{ $dayjs(message.client_checked_at).format('YYYY年MM月DD日 HH:mm') }} 既読</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="chat-group align-start">
                <div class="chat-avatar">
                  {{ title }}
                  <VAvatar variant="tonal">
                    <VImg
                      v-if="userData && userData.avatar"
                      :src="`${apiUrl}/storage/${userData.avatar}`"
                      cover
                    />

                    <VIcon
                      v-else
                      icon="tabler-user"
                    />
                  </VAvatar>
                </div>
              </div>
              <div class="chat-group align-start">
                <div class="chat-body d-inline-flex flex-column align-start">
                  <div class="text-right">
                    <span class="text-sm ms-1 text-disabled">{{ $dayjs(message.created_at).format('YYYY年MM月DD日 (dd)') }}</span>
                  </div>
                  <p
                    v-if="message.content"
                    class="content-overflow-wrap chat-content py-2 px-4 elevation-1 chat-left mb-3"
                    style="background-color: rgb(var(--v-theme-surface));"
                    v-html="message.content"
                  />
                  <a
                    v-if="message.file_path"
                    :href="`${apiUrl}/storage/${message.file_path}`"
                    target="_blank"
                  >
                    <div v-if="fileType(message.file_path) === 'png'">
                      <VImg
                        :width="400"
                        cover
                        :src="`${apiUrl}/storage/${message.file_path}`"
                        class="rounded"
                      />
                      <div>
                        <label>{{ file?.file_name }}</label>
                      </div>
                    </div>
                    <span v-else>{{
                      message.file_path.slice(
                        message.file_path.lastIndexOf("/") + 1,
                      )
                    }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VCardText>
      <VCardActions
        v-if="(!props.isAdmin && props.selected.status !== '完了' && props.selected.client_store_id === userData.user_id)
          || (props.selected.client_store?.id === userData.user_id) || (props.selected.owner_id === userData.user_id) || props.isAdmin"
      >
        <VCardText>
          <VFileInput
            v-model="file"
            show-size
            class="my-2"
          />

          <VTextarea
            v-model="content"
            placeholder="メッセージを入力..."
            variant="solo"
            rows="4"
          >
            <template #append-inner>
              <VBtn
                variant="flat"
                :disabled="!isFormValid"
                @click.stop="sendMessage"
              >
                <VIcon icon="tabler-send" />
              </VBtn>
            </template>
          </VTextarea>

          <div
            v-show="!isFormValid"
            class="v-input__details"
          >
            <div class="text-error">
              <p class="text-wrap">
                ※ ファイル・写真またはコメントが必要です。
              </p>
            </div>
          </div>
        </VCardText>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<style lang=scss>
.chat-log {
  .chat-content {
    border-end-end-radius: 6px;
    border-end-start-radius: 6px;

    &.chat-left {
      border-start-end-radius: 6px;
    }

    &.chat-right {
      border-start-start-radius: 6px;
    }
  }
  background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity))
}
.scrollable-dialog{
  overflow: visible !important;
}
</style>
